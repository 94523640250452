import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/salary',
    name: 'Salary',
    meta: { title: '工资列表' },
    component: () => import('../views/salary/Salary.vue')
  },
  {
    path: '/salary_detail/:id',
    name: 'SalaryDetail',
    meta: { title: '工资详情' },
    component: () => import('../views/salary/SalaryDetail.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: { title: '登录' },
    component: Login
  },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = 'MC-OMS'
  }
  const token = localStorage.getItem('token')
  if ((token === undefined || token === null) && to.name !== 'Login') {
    next({ name: 'Login' })
  } else {
    next()
  }
})
export default router
