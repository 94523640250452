<template>
  <div>
    <div class="error" v-if="showError">{{error==''?'服务不可用，请联系管理员':error}}</div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      times: 0,
      showError: false,
      error: ''
    }
  },
  methods: {
    getUserToken () {
      const that = this
      if (this.$route.query.code !== undefined) {
        this.code = this.$route.query.code
        axios.get('https://mcoms.micheng.cn/api/wxwork/getToken?code=' + this.$route.query.code).then(function (resp) {
          if (resp.data.code === 0) {
            localStorage.setItem('token', resp.data.token)
            that.$router.replace('/salary')
          }
          if (resp.data.code === 1) {
            that.error = resp.data.msg
            that.showError = true
          }
          if (resp.data.code === 2) {
            that.error = resp.data.msg
            that.showError = true
          }
        }).catch(function () {
          that.error = '网络错误'
          that.showError = true
        })
      } else {
        this.freshCode()
      }
    },
    freshCode () {
      const uri = 'https%3A%2F%2Fwxwork.micheng.cn%2Flogin'
      const url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wwff0699c82a7d0e8b&redirect_uri=' + uri + '&response_type=code&scope=snsapi_base&agentid=1000017#wechat_redirect'
      window.location.replace(url)
    },
    localTest () {
      localStorage.setItem('token', 'b9d0c6edadbd9d233029e1e57f5cc1a5')
      this.$router.replace('/salary')
    }
  },
  mounted () {
    localStorage.removeItem('token')
    this.getUserToken()
    // this.localTest()
  }
}
</script>

<style scoped>
.error{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
